import { ChevronDownIcon } from '@heroicons/react/solid';
import React from 'react';

interface IHeadingProps {
  title: string;
  reportShowFilterClick?: () => void;
  showFilter?: boolean;
}

const Heading: React.FC<IHeadingProps> = (props) => {
  const {
    title,
    reportShowFilterClick = () => ({}),
    showFilter = true,
  } = props;

  return (
    <div className="pb-5">
      <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        {showFilter ? (
          <div className="ml-4 mt-2 flex-shrink-0">
            <button
              type="button"
              onClick={() => reportShowFilterClick()}
              className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              style={{
                background: '#4BB15F',
              }}
            >
              <ChevronDownIcon
                className="-ml-1 mr-2 h-5 w-5"
                aria-hidden="true"
              />
              Filter
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Heading;
