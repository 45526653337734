import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Login from './Login';
import { PencilAltIcon } from '@heroicons/react/outline';
import { SectionPath } from '../enums/admin/Sections';
import { useAuth0 } from '@auth0/auth0-react';
import { userIsAdmin, userIsBroker } from '../utils/broker';

interface ISidebar {
  navigation: any;
}

function Sidebar(props: ISidebar) {
  const { navigation } = props;

  const { user } = useAuth0();

  const isBroker = userIsBroker(user);
  const isAdmin = userIsAdmin(user);

  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64">
        <div
          className="flex-1 flex flex-col min-h-0"
          style={{
            background: '#1C2954',
          }}
        >
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                className="h-auto w-auto"
                src="/logo.png"
                alt="FOCUS Solutions Logo"
              />
            </div>
            <nav
              className="mt-5 flex-1 px-2 space-y-1"
              style={{
                background: '1C2954',
              }}
            >
              {navigation.map((item: any) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                  )}
                  style={{
                    background: item.current ? '#4BB15F' : undefined,
                  }}
                >
                  <item.icon
                    className={classNames(
                      item.current
                        ? 'text-gray-300'
                        : 'text-gray-400 group-hover:text-gray-300',
                      'mr-3 flex-shrink-0 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              ))}
            </nav>
          </div>
          <div>
            {!!(isBroker || isAdmin) && (
              <Link
                to={SectionPath.broker_referral}
                className="group flex items-center px-2 py-2 text-sm font-medium rounded-md bg-focus-green text-white hover:bg-focus-green/80 mx-2 my-3"
              >
                <PencilAltIcon className="h-6 w-6 mr-3" />
                Create Referral
              </Link>
            )}
            <Login />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
