import { useAuth0 } from '@auth0/auth0-react';

const Dashboard = () => {
  const { user } = useAuth0();

  return (
    <>
      <div className="text-lg max-w-prose mx-auto text-center">
        <h1>
          <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Welcome to the FOCUS Business Solutions Trade Data Portal
          </span>
        </h1>
        <p className="mt-8 text-xl text-gray-500 leading-8 mb-10">
          Welcome {user?.nickname || user?.email}! The FOCUS Portal allows you
          to View, Search, and even Export your HTS, Supplier Response, and
          Issued Document data. Click an option to the left to view your data!
        </p>
      </div>
      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <HTSTable />
        <FTAIssuedTable />
        <FTASupplierTable />
      </div> */}
    </>
  );
};

export default Dashboard;
