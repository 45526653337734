import DataTable from '../components/DataTable';
import Heading from '../components/Heading';
import { useData } from '../hooks/useData';
import { ISortDirection } from '../interfaces/ISortDirection';
import {
  useQueryParams,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';
import { IBroker } from '../interfaces/IBroker';
import { ReferralColumn } from '../enums/ReferralsColumn';

const columns = [
  ReferralColumn.date_requested,
  ReferralColumn.date_due,
  ReferralColumn.plant_code,
  ReferralColumn.release_date,
  ReferralColumn.entry_number,
  ReferralColumn.created_at,
  ReferralColumn.updated_at,
];

const Referrals = () => {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    sortBy: withDefault(StringParam, 'created_at'),
    sortDirection: withDefault(StringParam, 'desc'),
  });

  const {
    status,
    data: _data,
    error,
    isLoading,
    isFetching,
  } = useData({
    table: 'broker_referrals',
    page: query.page,
    perPage: 50,
    sortBy: query.sortBy,
    sortDirection: query.sortDirection as ISortDirection,
  });

  return (
    <>
      <Heading title="Referrals" showFilter={false} />
      <DataTable
        reportPage={(_page: number) => setQuery({ ...query, page: _page })}
        reportSort={(sortBy: keyof IBroker, sortDirection: ISortDirection) => {
          setQuery({ ...query, sortBy: sortBy as any, sortDirection });
        }}
        status={status}
        data={_data}
        error={error}
        isLoading={isLoading || isFetching}
        columns={columns}
        page={query.page}
        hideExport
        isInternalTable
      />
    </>
  );
};

export default Referrals;
