import axios from 'axios';
import { useQuery } from 'react-query';
import { IDataItem } from '../interfaces/IDataItem';
import { IDataPayload } from '../interfaces/IDataPayload';
import { IPaginated } from '../interfaces/IPaginated';
import Http from '../services/http';

export function useData({
  table,
  page = 1,
  perPage = 10,
  sortBy,
  sortDirection,
  confidence_level,
  country_hts,
  date_classified_end,
  date_classified_start,
  description,
  hts_number,
  part_number,
  cert_number,
  blanket_end,
  blanket_start,
  supplier_name,
  plant_code,
  hts_number_empty,
  cert_number_empty,
  program,
  hts,
  supplier_id,
  effective_date,
  expiry_date,
  country_of_origin,
}: IPaginated<IDataItem>) {
  return useQuery<IDataPayload<IDataItem>>(
    [
      table,
      page,
      sortBy,
      sortDirection,
      confidence_level,
      country_hts,
      date_classified_end,
      date_classified_start,
      description,
      hts_number,
      part_number,
      cert_number,
      blanket_end,
      blanket_start,
      supplier_name,
      plant_code,
      hts_number_empty,
      cert_number_empty,
      program,
      hts,
      supplier_id,
      effective_date,
      expiry_date,
      country_of_origin,
    ],
    async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/data`, {
        params: {
          page,
          perPage,
          sortBy,
          sortDirection,
          table,
          confidence_level,
          country_hts,
          date_classified_end,
          date_classified_start,
          description,
          hts_number,
          part_number,
          cert_number,
          blanket_end,
          blanket_start,
          supplier_name,
          plant_code,
          hts_number_empty,
          cert_number_empty,
          program,
          hts,
          supplier_id,
          effective_date,
          expiry_date,
          country_of_origin,
        },
        headers: {
          Authorization: `Bearer ${Http.accessToken}`,
        },
      });

      return data;
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );
}
