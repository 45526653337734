import DataTable from '../components/DataTable';
import Heading from '../components/Heading';
import { useData } from '../hooks/useData';
import { ISortDirection } from '../interfaces/ISortDirection';
import { CustomObjectParam, removeEmpty } from '../utils/objects';
import {
  useQueryParams,
  NumberParam,
  StringParam,
  withDefault,
  BooleanParam,
} from 'use-query-params';
import { IBroker } from '../interfaces/IBroker';
import { BrokerColumn } from '../enums/BrokerColumn';
import BrokerDataTableFilter from '../components/data-table/BrokerDataTableFilter';
import { IBrokerFilters } from '../interfaces/IBrokerFilters';

const columns = [
  BrokerColumn.DatabaseCode,
  BrokerColumn.PlantCode,
  BrokerColumn.PartNumber,
  BrokerColumn.Description,
  BrokerColumn.HTS,
  BrokerColumn.CountryOfOrigin,
  BrokerColumn.SupplierID,
  BrokerColumn.SupplierName,
  BrokerColumn.SPIEligibility,
  BrokerColumn.SPI,
  BrokerColumn.EffectiveDate,
  BrokerColumn.ExpiryDate,
  BrokerColumn.DateTransmitted,
];

const Broker = () => {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    sortBy: withDefault(StringParam, 'Date Transmitted'),
    sortDirection: withDefault(StringParam, 'desc'),
    filters: withDefault(CustomObjectParam, {}),
    showFilter: withDefault(BooleanParam, false),
  });

  const {
    status,
    data: _data,
    error,
    isLoading,
    isFetching,
  } = useData({
    table: 'broker',
    page: query.page,
    perPage: 50,
    sortBy: query.sortBy,
    sortDirection: query.sortDirection as ISortDirection,
    ...query.filters,
  });

  const handleReportFilters = (filters: IBrokerFilters) => {
    setQuery({
      ...query,
      page: 1,
      filters: removeEmpty(filters),
    });
  };

  return (
    <>
      <Heading
        title="Broker"
        reportShowFilterClick={() =>
          setQuery({ ...query, showFilter: !query.showFilter })
        }
      />
      <BrokerDataTableFilter
        showFilter={query.showFilter}
        reportFilters={handleReportFilters}
      />
      <DataTable
        reportPage={(_page: number) => setQuery({ ...query, page: _page })}
        reportSort={(sortBy: keyof IBroker, sortDirection: ISortDirection) => {
          setQuery({ ...query, sortBy: sortBy as any, sortDirection });
        }}
        status={status}
        data={_data}
        error={error}
        isLoading={isLoading || isFetching}
        columns={columns}
        page={query.page}
        hideExport
        filters={{
          table: 'broker',
          page: query.page,
          perPage: 20,
          sortBy: query.sortBy,
          sortDirection: query.sortDirection,
          ...query.filters,
        }}
      />
    </>
  );
};

export default Broker;
