import { PlusIcon } from '@heroicons/react/outline';
import PrimaryButton from '../../buttons/PrimaryButton';
import {
  BrokerReferralFields,
  BrokerReferralPartsFields,
} from '../BrokerReferralForm';
import { PartsTable } from './PartsTable';
import TextAreaField from './TextAreaField';
import TextField from './TextField';
import { RequestTypeSelect } from './RequestTypeSelect';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const Parts = () => {
  const { values, setFieldValue, errors, touched, validateForm } =
    useFormikContext<typeof BrokerReferralFields>();

  const handleAddPartRequest = async () => {
    // Add current values to the parts array
    const newParts = [
      {
        request_type: values[BrokerReferralPartsFields.request_type],
        part_number: values[BrokerReferralPartsFields.part_number],
        part_description: values[BrokerReferralPartsFields.part_description],
      },
      ...(values.parts ?? []),
    ];
    setFieldValue('parts', newParts, true);

    try {
      setFieldValue(BrokerReferralPartsFields.part_number, '', true);
      setFieldValue(BrokerReferralPartsFields.part_description, '', true);
    } catch (error) {
      // Handle validation errors
      console.error(error);
    }
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.parts]);

  const isAddDisabled =
    !values[BrokerReferralPartsFields.request_type] ||
    !values[BrokerReferralPartsFields.part_number] ||
    !values[BrokerReferralPartsFields.part_description];

  return (
    <div className="shadow-sm border border-gray-300 p-5 w-full col-span-2 rounded-md bg-white/50 flex flex-col gap-6">
      <div className="w-full flex justify-between items-center">
        <h3 className="text-base leading-6 font-medium text-gray-900">
          Part Requests
        </h3>
        <PrimaryButton
          type="button"
          onClick={handleAddPartRequest}
          disabled={isAddDisabled}
        >
          <PlusIcon className="h-5 w-5 mr-2" />
          Add Part Request
        </PrimaryButton>
      </div>
      <div className="gap-3 flex-col grid grid-cols-1 sm:grid-cols-2">
        <RequestTypeSelect
          name={BrokerReferralPartsFields.request_type}
          label="Request Type"
        />
        <TextField
          name={BrokerReferralPartsFields.part_number}
          label="Part Number"
          classes="col-span-1"
        />
        <TextAreaField
          name={BrokerReferralPartsFields.part_description}
          label="Part Description"
          classes="col-span-2"
        />
      </div>
      <PartsTable />
      {errors['parts'] && touched['parts'] ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errors['parts']}
        </p>
      ) : null}
    </div>
  );
};

export default Parts;
